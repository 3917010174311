/* eslint-disable react/prop-types */
import React, { useState } from "react";
import dayjs from "dayjs";
import { DocumentCopy16Filled } from "@fluentui/react-icons";
import { handleCopy } from "../../../utils/utils";
import AppTooltip from "../../../../reusableComponents/AppTooltip";

/*global setTimeout*/

const ListItem = (props) => {
  const { columns, file } = props;
  const [copyText, setCopyText] = useState("Copy ID");

  return (
    <>
      {columns.map((column) => {
        return column.key === "creationDate" ? (
          <div key={file.id + column.key} className="table_cell_content">
            {column.key in file ? dayjs(file[column.key]).format("D/MM/YYYY HH:mm") : column.value}
          </div>
        ) : (
          <div key={file.id + column.key} className="table_cell_content">
            {column.key in file ? file[column.key] : column.value}
          </div>
        );
      })}
      <div
        key={file.id + "copy"}
        className="table_cell_action"
        onClick={(e) => {
          e.stopPropagation();
          handleCopy(file.id);
          setCopyText("Copied to clipboard!");
          setTimeout(() => {
            setCopyText("Copy ID");
          }, 3000);
        }}
      >
        <AppTooltip tipMessage={copyText} positioning={"above-end"}>
          <DocumentCopy16Filled />
        </AppTooltip>
      </div>
    </>
  );
};

export default ListItem;
